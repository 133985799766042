import Checkbox from 'components/Checkbox';
import Search from 'components/Search';
import React, { FC, useCallback } from 'react';
import css from './styles.module.scss';
import notFoundSVG from './../../assets/icons/not-found.svg';
import { logoUrl } from './../../const';
import { useStore } from 'effector-react';
import { $data } from './../../model/utxosAddress/state';
import cardano from './../../assets/icons/currency/cardano.svg';
import Burger from 'components/Burger';
import { useGenerateList, useTokensList } from '../../hooks';
import { $selectIDSend, setIdSend } from '../../model/selected/sendToken';
import {
  $openMobile,
  setMobileData,
} from '../../model/mobailSelect/mobailSelect';
import {
  $selectIDReceive,
  setIdReceive,
} from '../../model/selected/receiveToken';
import classNames from 'classnames';

interface PMobileMenu {
  setOpenSearch?: any;
  title: string;
}

const mapStore = new Map([
  ['send', { setIdS: setIdSend, $selectID: $selectIDSend }],
  ['receive', { setIdS: setIdReceive, $selectID: $selectIDReceive }],
]);

const MobileMenu: FC<PMobileMenu> = ({ setOpenSearch, title }) => {
  const { data } = useStore($data);

  const [isSearch, setSearch, value, setValue, tokens, onClose, onOpen] =
    useGenerateList(data);

  const { type, isOpen } = useStore($openMobile);

  const store = mapStore.get(type);

  const onChangeSendToken = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { isConnected, value } = e.target;
      if (isConnected) {
        store?.setIdS(value);
        setMobileData({ isOpen: false, type: 'default' });
      }
    },
    [setIdSend, store?.setIdS]
  );

  const selectID = useStore(store?.$selectID ?? $selectIDSend);
  const unSelectID = useStore(
    type === 'send' ? $selectIDReceive : $selectIDSend
  );

  return (
    <div className={css.column}>
      <div className={css.columnHeader} onClick={setOpenSearch}>
        {title}
        <Burger
          openMobile={true}
          toggleMobile={() => {
            setMobileData({ isOpen: false, type: 'default' });
          }}
        />
      </div>
      <div className={css.search}>
        <Search
          title={type[0].toUpperCase() + type.substring(1)}
          open={isSearch}
          onOpen={onOpen}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onClose={onClose}
        />
      </div>

      <div
        className={classNames(css.columnItems, {
          _notFound: tokens.length === 0,
        })}
      >
        {tokens.map((item) => {
          return (
            <Checkbox
              title={item.assetNameStr}
              icon={item.logo ? `${logoUrl}${item.logo}` : cardano}
              key={item.assetId}
              name="left"
              value={item.assetId}
              onChange={onChangeSendToken}
              checked={selectID === item.assetId}
              disabled={unSelectID === item.assetId}
            />
          );
        })}
      </div>
      {tokens.length === 0 && (
        <div className={css.notFound}>
          <span>We couldn’t find anything matching your search</span>
          <img className={css.icon} src={notFoundSVG} alt="not found" />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;

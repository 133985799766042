import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface PButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  classname?: string;
  text: string;
}

const Button: FC<PButton> = ({ classname, text, ...buttonAttributes }) => {
  return (
    <button
      className={cn(css.btn, classname, { _isDotted: true })}
      {...buttonAttributes}
    >
      {text}
    </button>
  );
};

export default Button;

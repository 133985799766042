import React, { FC, useCallback, useState } from 'react';
import css from './styles.module.scss';
import copySVG from '../../assets/icons/copy.svg';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import Button from 'components/Button';
import { useStore } from 'effector-react';
import { $selectTokenReceive } from '../../model/selected/receiveToken';
import { Token } from '../../model/api';
import { $selectTokenSend } from '../../model/selected/sendToken';
import { copyToBuffer } from '../../helper/copyTobuffer';

function getCurrencyName(data: Token | null): string | undefined {
  if (!data) return undefined;
  if (data.ticker) return data.ticker;
  return data.assetNameStr;
}
interface PSwap {
  classname?: any;
}

function getAddress(receiveData: Token | null, sendData: Token | null): string {
  const address = receiveData?.address ?? '';
  if (address === 'сardano') {
    return sendData?.address ?? '';
  }
  return address;
}

const Swap: FC<PSwap> = ({ classname }) => {
  const sendData = useStore($selectTokenSend);
  const receiveData = useStore($selectTokenReceive);

  const address = getAddress(receiveData, sendData);

  const [btnText, setBtnText] = useState<'Copy Address' | 'Copied!'>(
    'Copy Address',
  );
  const sendCurrency = getCurrencyName(sendData);
  const receiveCurrency = getCurrencyName(receiveData);

  const copyAddress = useCallback(() => {
    copyToBuffer(address);
    setBtnText('Copied!');
    setTimeout(() => {
      setBtnText('Copy Address');
    }, 1500);
  }, [address]);
  return (
    <div className={cn(css.swap, classname)}>
      <div className={css.swapTitle}>Swap in one step</div>
      {sendCurrency && (
        <p className={css.swapPrompt}>Send {sendCurrency} to address</p>
      )}
      <div className={css.swapCopy}>
        <div className={css.swapField}>
          <input type="text" readOnly value={address} />
          <ReactSVG
            src={copySVG}
            className={css.copyBtn}
            onClick={copyAddress}
          />
        </div>
      </div>
      <div className={css.swapCopuBtn}>
        <Button text={btnText} onClick={copyAddress} />
      </div>
      {receiveCurrency && (
        <div className={css.swapNote}>
          Converted funds <strong>{receiveCurrency}</strong> will be transferred
          to sender address
        </div>
      )}
    </div>
  );
};

export default Swap;

import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import { FiChevronDown } from 'react-icons/fi';
import { defCurrency } from '../../const';

interface PInput extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  max?: string;
  currency?: string;
  logo?: string;
  toggleMobile?: any;
  longName?: string;
  error?: boolean;
}

function currencyMobail(currency?: string, longName?: string): string {
  if (currency === defCurrency && longName) return longName;
  if (currency) return currency;

  return 'Select';
}

const Input: FC<PInput> = ({
  title,
  max,
  currency,
  logo,
  toggleMobile,
  longName,
  error,
  ...inputAttributes
}) => {
  return (
    <div
      className={cn(css.input, {
        _isError: error,
      })}
    >
      <div className={css.header}>
        <span className={css.title}>{title}</span>
        <div className={css.select} onClick={toggleMobile}>
          {currencyMobail(currency, longName)}
          <img className={css.icon} src={logo} alt="icon" />
          <FiChevronDown size={18} />
        </div>
      </div>

      <div className={css.fieldBody}>
        <input type="text" className={css.field} {...inputAttributes} />
        <div className={css.currency}>{currency}</div>
      </div>

      {longName && (
        <div className={css.max}>
          <span>{longName}</span>
        </div>
      )}
      {max && (
        <div className={css.max}>
          Max: <span>{max}</span>
        </div>
      )}
    </div>
  );
};

export default Input;

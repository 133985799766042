import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface PCheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  classname?: StyleSheet;
  icon?: string;
}
const Checkbox: FC<PCheckbox> = ({
  title,
  classname,
  icon,
  ...inputAttributes
}) => {
  return (
    <div>
      <label
        className={cn(css.body, classname, {
          _isDisable: inputAttributes.disabled,
        })}
      >
        <input className={css.input} type="radio" {...inputAttributes} />

        <div className={css.title}>
          {icon && <img className={css.icon} src={icon} alt="icon" />}
          <span>{title}</span>
        </div>
      </label>
    </div>
  );
};

export default Checkbox;

import React from 'react';
import css from './styles.module.scss';
import Button from 'components/Button';

function Info() {
  return (
    <div className={css.info}>
      <div className={css.infoTitle}>How does it work?</div>
      <div className={css.infoText}>
        Cardano is a blockchain platform for changemakers, innovators, and
        visionaries, with the tools and technologies required to create
        possibility for the many, as well as the few, and bring about positive
        global change.
      </div>
      <Button text="Read more" classname={css.infoBtn} />
    </div>
  );
}

export default Info;

import request from '../core/api/request';

type PUtxosAddress = {
  address: string;
};

export type Token = {
  assetId: string;
  assetName: string;
  assetNameStr: string;
  description: string | null;
  fingerprint: string;
  logo: string | null;
  metadataHash: string | null;
  name: string | null;
  quantity: string;
  ticker: string | null;
  decimals: number | null;
  address: string;
};
export type RUtxosAddress = {
  address: string;
  tokens: Token[];
  value: number;
};
// запрос данных по токенам адреса
export const utxosAddress = request<RUtxosAddress, PUtxosAddress>({
  method: 'utxos_address',
  token: false,
});

export type RGetTokenInfo = {
  token: string;
  quantity: number;
  exchange: number;
};
export type PGetTokenInfo = {
  token: string;
};

export const getTokenInfo = request<RGetTokenInfo, PGetTokenInfo>({
  method: 'get_token_info',
  token: false,
});

export type TokenInfo = {
  assetId: string;
  quantity: number;
  exchange: number;
};
export type RGetAllTokenInfo = {
  tokens: TokenInfo[];
};
export const getAllTokenInfo = request<RGetAllTokenInfo>({
  method: 'get_all_tokens_info',
  token: false,
});

import React, { FC, useEffect, useRef } from 'react';
import css from './styles.module.scss';

import cn from 'classnames';
import { FaSearch } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

interface PSearch extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const Search: FC<PSearch> = ({
  title,
  open,
  onOpen,
  onClose,
  ...inputAttributes
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isExistsValue = Boolean(inputAttributes.value);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target;
      if (!(target instanceof Element)) return;
      if (wrapperRef?.current && !wrapperRef.current.contains(target)) {
        if (isExistsValue) return;
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isExistsValue]);

  return (
    <div
      className={cn(css.search, {
        _isOpen: open,
      })}
    >
      <div className={css.wrapper}>
        {open && (
          <div className={css.body} ref={wrapperRef}>
            <input className={css.input} type="text" {...inputAttributes} />
            <FiX size={22} onClick={onClose} className={css.cross} />
          </div>
        )}

        {!open && (
          <>
            <FaSearch size={17} onClick={onOpen} />
            <div className={css.title} onClick={onOpen}>
              {title}
            </div>
          </>
        )}
      </div>
      <div className={css.mobile}>
        <FaSearch size={17} className={css.icon} />
        <input
          className={css.input}
          type="text"
          autoFocus
          {...inputAttributes}
        />
      </div>
    </div>
  );
};

export default Search;

import React, { useCallback, useEffect, useState } from 'react';
import { $data, getDataFx } from './model/utxosAddress/state';
import { address } from './const';
import { useStore } from 'effector-react';
import { RUtxosAddress, Token } from './model/api';
import { $openMobile } from './model/mobailSelect/mobailSelect';
import { $selectIDSend, setIdSend } from './model/selected/sendToken';
import { $selectIDReceive, setIdReceive } from './model/selected/receiveToken';

function filterTokens(value: string, item: Token): boolean {
  const isAssetName = item.assetNameStr.toUpperCase().includes(value);
  const isAssetNameStr = item.assetNameStr.toUpperCase().includes(value);
  const isName = item.name?.toUpperCase().includes(value);

  return Boolean(isAssetName || isAssetNameStr || isName);
}

export function useGenerateList(
  data: RUtxosAddress | null,
): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  string,
  React.Dispatch<React.SetStateAction<string>>,
  Token[],
  () => void,
  () => void,
] {
  const [isSearch, setSearch] = useState(false);
  const [value, setValue] = useState('');

  const tokens = (data?.tokens ?? []).filter((item) =>
    filterTokens(value.toUpperCase(), item),
  );
  const onClose = () => {
    setValue('');
    setSearch(false);
  };
  const onOpen = () => {
    setSearch(true);
  };

  return [isSearch, setSearch, value, setValue, tokens, onClose, onOpen];
}

export function useTokensList() {
  useEffect(() => {
    getDataFx(address);
  }, []);

  const { data, status } = useStore($data);

  const [
    isSearchSend,
    _,
    sendValue,
    setSendValue,
    sendTokens,
    onCloseSend,
    onOpenSend,
  ] = useGenerateList(data);
  const [
    isSearchReceive,
    setSearchReceive,
    receiveValue,
    setReceiveValue,
    receiveTokens,
    onCloseReceive,
    onOpenReceive,
  ] = useGenerateList(data);

  const { isOpen } = useStore($openMobile);
  const selectIDSend = useStore($selectIDSend);
  const selectIDReceive = useStore($selectIDReceive);

  const onChangeSendToken = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { isConnected, value } = e.target;
      if (isConnected) {
        setIdSend(value);
        return;
      }
    },
    [setIdSend],
  );

  const onChangeReceiveToken = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;
      if (checked) {
        setIdReceive(value);
        return;
      }
    },
    [setIdReceive],
  );

  const [focusSend, setFocusSend] = useState(false);

  useEffect(() => {
    if (!isSearchSend) {
      setFocusSend(false);
    }
  }, [isSearchSend]);

  const [focusReceive, setFocusReceive] = useState(false);

  useEffect(() => {
    if (!isSearchReceive) {
      setFocusReceive(false);
    }
  }, [isSearchReceive]);

  return {
    isSearchSend,
    onOpenSend,
    sendValue,
    setSendValue,
    onCloseSend,
    sendTokens,
    isSearchReceive,
    onOpenReceive,
    receiveValue,
    setReceiveValue,
    onCloseReceive,
    receiveTokens,

    isOpen,
    focusReceive,
    setFocusSend,
    onChangeSendToken,
    selectIDSend,
    focusSend,
    setFocusReceive,
    onChangeReceiveToken,
    selectIDReceive,

    status,
  };
}

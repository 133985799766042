import React from 'react';
import SmallLogoSvg from '../../assets/icons/small-logo.svg';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import css from './styles.module.scss';

const Footer = () => {
  return (
    <>
      <div className={css.menuContacts}>
        <NavLink to={{ pathname: `/` }}>About</NavLink>
        <NavLink to={{ pathname: `/` }}>Support</NavLink>
        <NavLink to={{ pathname: `/` }}>Contacts</NavLink>
      </div>
      <div className={css.menuBottom}>
        <ReactSVG src={SmallLogoSvg} className={css.logo} />
      </div>
    </>
  );
};

export default Footer;

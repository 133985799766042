import { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface POption {
  title: string;
  classname?: StyleSheet;
}

const Option: FC<POption> = ({ title, classname }) => {
  return (
    <label className={cn(css.body, classname)}>
      <input className={css.input} type="checkbox" name="checkbox" />
      <div className={css.title}>{title}</div>
    </label>
  );
};

export default Option;

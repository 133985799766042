import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface PBurger {
  classname?: string;
  openMobile?: any;
  toggleMobile?: any;
}

const Burger: FC<PBurger> = ({ classname, openMobile, toggleMobile }) => {
  const [open, setOpen] = useState(openMobile);

  const toggle = () => {
    setOpen(!open);
  };
  return (
    <div
      className={cn(css.burger, classname, {
        _isOpen: open,
      })}
      onClick={openMobile ? toggleMobile : toggle}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Burger;

import React, { FC, useEffect } from 'react';
import LogoSvg from '../../assets/icons/logo.svg';
import css from './styles.module.scss';
import Burger from 'components/Burger';
import { ReactSVG } from 'react-svg';

const Header: FC = () => {
  return (
    <div className={css.header}>
      <ReactSVG src={LogoSvg} className={css.logo} />
      <Burger />
    </div>
  );
};

export default Header;

import React, { useCallback, useEffect, useState } from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import Option from 'components/Option';
import Checkbox from 'components/Checkbox';
import Search from 'components/Search';
import Header from 'components/Header';
import Converter from 'components/Converter';
import Swap from 'components/Swap';
import Info from 'components/Info';
import Footer from 'components/Footer';
import cardano from 'assets/icons/currency/cardano.svg';
import { logoUrl } from './const';
import notFoundSVG from 'assets/icons/not-found.svg';
import { useTokensList } from './hooks';

import MobileMenu from 'components/MobileMenu';

import { Token } from './model/api';
import { setIdReceive } from './model/selected/receiveToken';
import { setIdSend } from './model/selected/sendToken';

function getTitle(token: Token): string {
  const name = token.name ? token.name : token.assetNameStr;
  return `${name} ${token.ticker ? `(${token.ticker})` : ''}`;
}
function App() {
  const {
    isSearchSend,
    onOpenSend,
    sendValue,
    setSendValue,
    onCloseSend,
    sendTokens,
    isSearchReceive,
    onOpenReceive,
    receiveValue,
    setReceiveValue,
    onCloseReceive,
    receiveTokens,
    isOpen,
    focusReceive,
    setFocusSend,
    onChangeSendToken,
    selectIDSend,
    focusSend,
    setFocusReceive,
    onChangeReceiveToken,
    selectIDReceive,
    status,
  } = useTokensList();

  return (
    <div
      className={cn(css.body, {
        isMobile: isOpen,
      })}
    >
      <div className={css.menu}>
        <div className={css.menuContainer}>
          <div className={css.menuBody}>
            <Header />
            <div className={css.menuOptions}>
              <Option title="All" />
              <Option title="Popular" />
              <Option title="Recent" />
            </div>
            <div className={css.menuExchange}>
              <div
                className={cn(css.column, {
                  _isTransparent: focusReceive,
                })}
              >
                <div className={css.columnHeader}>
                  <Search
                    title="Send"
                    open={isSearchSend}
                    onOpen={onOpenSend}
                    value={sendValue}
                    onChange={(e) => {
                      setSendValue(e.target.value);
                    }}
                    onClose={onCloseSend}
                    onFocus={() => {
                      setFocusSend(true);
                    }}
                    onBlur={() => {
                      setFocusSend(false);
                    }}
                    autoFocus={isSearchSend}
                  />
                </div>

                <div
                  className={cn(css.columnItems, {
                    _notFound: sendTokens.length === 0,
                  })}
                >
                  {sendTokens.map((item, index) => {
                    return (
                      <Checkbox
                        title={getTitle(item)}
                        icon={item.logo ? `${logoUrl}${item.logo}` : cardano}
                        key={item.assetId}
                        value={item.assetId}
                        name="left"
                        onChange={onChangeSendToken}
                        onClick={(e) => {
                          if (item.assetId === selectIDSend) {
                            setIdSend('');
                          }
                        }}
                        checked={item.assetId === selectIDSend}
                        disabled={item.assetId === selectIDReceive}
                      />
                    );
                  })}
                  {sendTokens.length === 0 && status === 'done' && (
                    <div className={css.notFound}>
                      <span>
                        We couldn’t find anything matching your search
                      </span>
                      <img
                        className={css.icon}
                        src={notFoundSVG}
                        alt="not found"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className={cn(css.column, {
                  _isTransparent: focusSend,
                })}
              >
                <div className={css.columnHeader}>
                  <Search
                    title="Receive"
                    open={isSearchReceive}
                    onOpen={onOpenReceive}
                    value={receiveValue}
                    onChange={(e) => {
                      setReceiveValue(e.target.value);
                    }}
                    onClose={onCloseReceive}
                    onFocus={() => {
                      setFocusReceive(true);
                    }}
                    onBlur={() => {
                      setFocusReceive(false);
                    }}
                    autoFocus={isSearchReceive}
                  />
                </div>
                <div
                  className={cn(css.columnItems, {
                    _notFound: sendTokens.length === 0,
                  })}
                >
                  {receiveTokens.map((item, index) => {
                    return (
                      <Checkbox
                        title={getTitle(item)}
                        icon={item.logo ? `${logoUrl}${item.logo}` : cardano}
                        key={item.assetId}
                        name="right"
                        value={item.assetId}
                        onChange={onChangeReceiveToken}
                        onClick={(e) => {
                          if (item.assetId === selectIDReceive) {
                            setIdReceive('');
                          }
                        }}
                        checked={item.assetId === selectIDReceive}
                        disabled={item.assetId === selectIDSend}
                      />
                    );
                  })}
                  {receiveTokens.length === 0 && status === 'done' && (
                    <div className={css.notFound}>
                      <span>
                        We couldn’t find anything matching your search
                      </span>
                      <img
                        className={css.icon}
                        src={notFoundSVG}
                        alt="not found"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <div className={css.mobile}>
          <div className={css.container}>
            <Header />
            <Converter />
          </div>
          <Swap classname={css.swap} />
          <div className={css.container}>
            <Info />
            <Footer />
          </div>
        </div>

        <div className={cn(css.circleBg, css.menuBgLeft)} />
        <div className={cn(css.circleBg, css.menuBgRight)} />
      </div>

      <div className={css.screen}>
        <div className={css.screenContent}>
          <Converter />
          <Swap />
          <Info />
        </div>
        <div className={cn(css.circleBg, css.menuBgbottom)} />
      </div>

      {isOpen && (
        <div className={css.mobileSearch}>
          <MobileMenu title="Send" />
        </div>
      )}
    </div>
  );
}

export default App;

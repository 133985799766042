import React, { FC, useCallback, useEffect } from 'react';
import css from './styles.module.scss';
import Input from 'components/Input';
import { FaArrowRight } from 'react-icons/fa';
import cardano from './../../assets/icons/currency/cardano.svg';
import { logoUrl } from './../../const';
import { setMobileData } from '../../model/mobailSelect/mobailSelect';

import { IoAlertCircleSharp } from 'react-icons/io5';
import { useConvert, useWatchers } from './hook';
import { matchPath } from 'react-router';
import { readUrlParams } from '../../helper/readUrlParams';

const match = matchPath<{ params: string }>(window.location.pathname, {
  path: '/:params',
  exact: true,
  strict: false,
});

const urlData = readUrlParams(match?.params.params);

const Converter: FC = () => {
  const {
    formatMax,
    sendCurrency,
    sendData,
    sendLongName,
    onChangeSend,
    formik,
    receiveCurrency,
    receiveData,
    formatMaxReceive,
    receiveLongName,
    errors,
  } = useConvert(urlData.send);

  useWatchers(formik.values.send);

  return (
    <>
      <div className={css.converter}>
        <Input
          title="You send"
          max={formatMax}
          currency={sendCurrency}
          logo={sendData?.logo ? `${logoUrl}${sendData?.logo}` : cardano}
          toggleMobile={() => setMobileData({ isOpen: true, type: 'send' })}
          longName={sendLongName}
          onChange={onChangeSend}
          name="send"
          value={formik.values.send}
          error={Boolean(formik.errors.send)}
        />
        <FaArrowRight size={20} color="#fff" className={css.arrow} />
        <Input
          title="You will receive"
          currency={receiveCurrency}
          logo={receiveData?.logo ? `${logoUrl}${receiveData?.logo}` : cardano}
          longName={receiveLongName}
          toggleMobile={() => setMobileData({ isOpen: true, type: 'receive' })}
          max={formatMaxReceive}
          value={formik.values.receive}
          readOnly
        />
      </div>

      <ul>
        <li className={errors[0] ? css.listError : undefined}>
          - Transaction must be at least 2 ADA. This amount will be refunded.
        </li>
        <li className={errors[1] ? css.listError : undefined}>
          - Transaction must contain chargeback fee amount about 0.2 ADA.
        </li>
        <li className={errors[2] ? css.listError : undefined}>
          - Transaction amount minus the commission and the fixed amount must be
          sufficient to purchase at least one token.
        </li>
      </ul>
      {formik.errors.send && (
        <p className={css.error}>
          <IoAlertCircleSharp />
          {formik.errors.send}
        </p>
      )}
    </>
  );
};

export default Converter;
